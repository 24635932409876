
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$futa-roll-primary: mat.define-palette(mat.$indigo-palette);
$futa-roll-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$futa-roll-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$futa-roll-theme: mat.define-light-theme((
  color: (
    primary: $futa-roll-primary,
    accent: $futa-roll-accent,
    warn: $futa-roll-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($futa-roll-theme);

/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: jakarta-medium;
  src: url("assets/fonts/plus_jakarta_display_medium.otf");
}
@font-face {
  font-family: jakarta;
  src: url("assets/fonts/plus_jakarta_display_regular.otf");
}

@layer base {
  html {
    font-family: "jakarta";
  }
  .fonts-normal {
    font-family: "jakarta";
  }
  .fonts-bold {
    font-family: "jakarta-medium";
  }

  .layouts-symbols-badge {
    @apply text-center rounded-full w-[56px] h-[56px] flex justify-center items-center text-white;
  }
  .layouts-symbols-badge-small {
    @apply text-center rounded-full w-[36px] h-[36px] flex justify-center items-center text-white;
  }

  .animate-pulse-children {
    @apply animate-pulse p-4;
  }
}

@layer components {
  .text-title {
    @apply text-2xl font-bold;
  }
  .colors-primary-text {
    @apply text-[#5d31ff];
  }
  .colors-primary-bg {
    @apply bg-[#5d31ff] text-white;
  }
  .colors-primary-bg-disabled {
    @apply bg-[#5d31ff] bg-opacity-50 pointer-events-none text-white;
  }
  .colors-primary-dark-bg {
    @apply bg-[#10004c] text-white;
  }
  .colors-primary-border {
    @apply border-[#5d31ff];
  }
  .colors-primary-dark-border {
    @apply border-[#10004c];
  }
  .colors-background {
    @apply bg-[#f2f2f7] text-[#10004c];
  }
  .colors-background-card {
    @apply bg-[#f2f2f7];
  }
  .colors-surface {
    @apply bg-white text-[#10004c];
  }
  .color-failed-status {
    @apply text-red-700;
  }
  .color-pending-status {
    @apply text-[#fca600];
  }
  .color-complete-status {
    @apply text-green-700;
  }
  .colors-on-background {
    @apply text-[#10004c];
  }
  .color-income-icon-bg {
    @apply bg-[#bde6c0]
  }
  .color-income-icon-text {
    @apply text-[#6cd671]
  }
  .colors-on-surface {
    @apply text-[#10004c];
  }
  .colors-text-error {
    @apply text-red-500;
  }
  .failed-status {
    @apply bg-red-100 border-red-400 text-red-700 rounded-full py-1 px-2;
  }
  .pending-status {
    @apply bg-yellow-100 border-yellow-400 color-pending-status rounded-full py-1 px-2;
  }
  .completed-status {
    @apply bg-green-100 border-green-400 text-green-700 rounded-full py-1 px-2;
  }
  .alert-info {
    @apply bg-purple-100 text-purple-700 border-purple-400;
  }
  .alert-success {
    @apply bg-green-100 border-green-400 text-green-700;
  }
  .alert-error {
    @apply bg-red-100 border-red-400 text-red-700;
  }
  .dropdown-button {
    @apply bg-[#ebe5ff] hover:bg-[#f2f2f7] divide-gray-100;
  }
  .stepper-buttons {
    @apply bg-white border-gray-300  hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500;
  }
  .stepper-border {
    @apply border-indigo-600;
  }
  .spinner-border {
    @apply border-t-[#5d31ff] border-gray-300;
  }
  .stepper-bg {
    @apply bg-indigo-600;
  }
  .stepper-text {
    @apply text-indigo-600;
  }
  .option-card-selection {
    @apply ring-indigo-600 bg-white;
  }
  .schedule-note {
    @apply bg-gray-50 border border-gray-300 rounded-lg focus:ring-[##10004c] focus:border-[#10004c];
  }
  .download-button {
    @apply bg-[#5d31ff] hover:bg-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-300;
  }

  .download-button2 {
    @apply bg-white hover:bg-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-300;
  }

  .w-menu-panel {
    @apply w-[284px];
  }
  .ml-menu-panel {
    @apply ml-[284px];
  }
  .h-title {
    @apply h-[42px] lg:h-[56px];
  }

  .inputs-group-span-1 {
    @apply flex flex-col text-left col-span-1;
  }
  .inputs-group-span-2 {
    @apply flex flex-col lg:col-span-2 text-left;
  }
  .inputs-group-span-full {
    @apply flex flex-col lg:col-span-full text-left;
  }
  .inputs-field-default {
    @apply border colors-primary-dark-border p-2 rounded;
  }
  .payment-progress-text-color {
    @apply text-[#FCA600];
  }
  .payment-progress-button {
    @apply bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300;
  }
}

@layer utilities {
  .colors-blue-bg {
    @apply bg-blue-200;
  }
  .colors-border-error {
    @apply border-red-400;
  }
}

.snackbar-error {
  .mdc-snackbar__surface {
    background-color: #fef2f2 !important;
  }
  .mdc-snackbar__label {
    color: #ff0000 !important;
  }
  // .mdc-snackbar__label {
  //   color: white !important;
  // }
}



// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
